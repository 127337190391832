import { useUserProfileContext } from '@gain/modules/user'
import { isAdministrator } from '@gain/utils/user'
import { styled } from '@mui/material/styles'

import MainNavHeader from '../../Components/Navigation/MainNavigation/MainNavHeader'
import MainNavSectionTitle from '../../Components/Navigation/MainNavigation/MainNavSectionTitle'
import { ASSETS_PAGE_PATH, NAV_ASSETS } from '../../routes/assets/assets-route-path'
import {
  CONFERENCE_EDITIONS_PAGE_PATH,
  NAV_CONFERENCE_EDITIONS,
} from '../../routes/conference-editions'
import { CONFERENCES_PAGE_PATH, NAV_CONFERENCES } from '../../routes/conferences'
import { NAV_PROJECTS, PROJECTS_PAGE_PATH } from '../../routes/projects/projects-route-path'
import { NAV_ADVISORS, ROUTE_ADVISORS_PATH } from '../../routes/route-advisors/route-advisors-path'
import { DEALS_PAGE_PATH, NAV_DEALS } from '../../routes/route-deals'
import { INVESTORS_PAGE_PATH, NAV_INVESTORS } from '../../routes/route-investors'
import { NAV_PERSONS, ROUTE_PERSONS_PATH } from '../../routes/route-persons/route-persons-path'
import { NAV_USERS, ROUTE_USERS_PATH } from '../../routes/route-users/route-users-path'
import { NAV_TAGS, TAGS_PAGE_PATH } from '../../routes/tags/tags-route-path'
import { NAV_VENEUS, VENUES_PAGE_PATH } from '../../routes/venues/venues-route-path'
import { LIST_USERS_METHOD } from '../../util/methods'
import {
  NAV_COMPETITORS,
  NAV_CUSTOMERS,
  NAV_EMPLOYEES,
  NAV_IN_THE_NEWS,
  NAV_INDUSTRIES,
  NAV_LEGAL_ENTITIES,
  NAV_RESEARCH,
} from '../../util/navigationTitles'
import { CUSTOMER_USERS_PATH } from '../../util/path'
import {
  DEFAULT_COMPETITORS_LIST_PATH,
  DEFAULT_CUSTOMERS_LIST_PATH,
  DEFAULT_INDUSTRIES_LIST_PATH,
  DEFAULT_INTHENEWS_LIST_PATH,
  DEFAULT_LEGAL_ENTITIES_LIST_PATH,
  DEFAULT_RESEARCH_LIST_PATH,
  DEFAULT_SORT_FIRST_NAME,
} from '../../util/pathDefaults'
import { pathGenerate } from '../../util/pathGenerate'
import MainNavigationFooter from './main-navigation-footer.component'
import MainNavigationItem from './main-navigation-item.component'

const MENU_LINKS = [
  {
    title: NAV_ASSETS,
    path: ASSETS_PAGE_PATH,
    shortcuts: [
      {
        title: NAV_PROJECTS,
        path: PROJECTS_PAGE_PATH,
      },
    ],
  },
  {
    title: NAV_INDUSTRIES,
    path: DEFAULT_INDUSTRIES_LIST_PATH,
  },
  {
    title: NAV_INVESTORS,
    path: INVESTORS_PAGE_PATH,
  },
  {
    title: NAV_PERSONS,
    path: ROUTE_PERSONS_PATH,
  },
  {
    title: NAV_DEALS,
    path: DEALS_PAGE_PATH,
  },
  {
    title: NAV_ADVISORS,
    path: ROUTE_ADVISORS_PATH,
  },
  {
    title: 'Conferences',
    path: CONFERENCE_EDITIONS_PAGE_PATH,
    shortcuts: [
      {
        title: NAV_CONFERENCES,
        path: CONFERENCES_PAGE_PATH,
      },
      {
        title: NAV_CONFERENCE_EDITIONS,
        path: CONFERENCE_EDITIONS_PAGE_PATH,
      },
      {
        title: NAV_VENEUS,
        path: VENUES_PAGE_PATH,
      },
    ],
  },
  {
    title: NAV_LEGAL_ENTITIES,
    path: DEFAULT_LEGAL_ENTITIES_LIST_PATH,
  },
  {
    title: NAV_COMPETITORS,
    path: DEFAULT_COMPETITORS_LIST_PATH,
  },
]

const DISCOVER_LINKS = [
  {
    title: NAV_IN_THE_NEWS,
    path: DEFAULT_INTHENEWS_LIST_PATH,
  },
  {
    title: NAV_RESEARCH,
    path: DEFAULT_RESEARCH_LIST_PATH,
  },
]

const CUSTOMER_LINKS = [
  {
    title: NAV_CUSTOMERS,
    path: DEFAULT_CUSTOMERS_LIST_PATH,
  },
  {
    title: NAV_USERS,
    path: ROUTE_USERS_PATH,
  },
]

const EMPLOYEES_LINKS = [
  {
    title: NAV_EMPLOYEES,
    path: pathGenerate(CUSTOMER_USERS_PATH, {
      method: LIST_USERS_METHOD,
      sort: DEFAULT_SORT_FIRST_NAME,
      id: 1,
    }),
  },
]

const Root = styled('div')({
  display: 'flex',
  flexFlow: 'column nowrap',
  height: '100%',
  justifyContent: 'flex-start',

  '> *': {
    flex: '0 1 auto',
  },
})

const Container = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  overflow: 'auto',
  paddingTop: theme.spacing(2),
  borderTop: '1px solid hsla(0,0%,100%,.1)',
}))

const StyledUl = styled('ul')({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
})

export default function MainNavigation() {
  const userProfile = useUserProfileContext()

  return (
    <Root>
      <MainNavHeader data={userProfile} />

      <Container>
        <MainNavSectionTitle>Content</MainNavSectionTitle>
        <StyledUl>
          {MENU_LINKS.map((link) => (
            <MainNavigationItem
              key={link.path}
              {...link}
            />
          ))}

          {isAdministrator(userProfile?.role) && (
            <MainNavigationItem
              key={TAGS_PAGE_PATH}
              path={TAGS_PAGE_PATH}
              title={NAV_TAGS}
            />
          )}
        </StyledUl>

        <MainNavSectionTitle>Home</MainNavSectionTitle>
        <StyledUl>
          {DISCOVER_LINKS.map((link) => (
            <MainNavigationItem
              key={link.path}
              {...link}
            />
          ))}
        </StyledUl>

        {isAdministrator(userProfile?.role) && (
          <>
            <MainNavSectionTitle>Customer</MainNavSectionTitle>
            <StyledUl>
              {CUSTOMER_LINKS.map((link) => (
                <MainNavigationItem
                  key={link.path}
                  {...link}
                />
              ))}
            </StyledUl>

            <MainNavSectionTitle>Gain</MainNavSectionTitle>
            <StyledUl>
              {EMPLOYEES_LINKS.map((link) => (
                <MainNavigationItem
                  key={link.path}
                  {...link}
                />
              ))}
            </StyledUl>
          </>
        )}
      </Container>

      <MainNavigationFooter />
    </Root>
  )
}
